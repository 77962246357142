import React, { Fragment, useState, useEffect, useContext, useCallback, useRef } from "react"
import MetaTags from "../../src/MetaTags"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import ProductList from "../../src/ProductList"
import LayoutFirebase from "../../src/LayoutFirebase"
import { FirebaseContext } from "../../src/Firebase"
import Loading from "../../src/Loading"
import useRedirectToProfile from "../../src/useRedirectToProfile"
import LoadingButton, { Button } from "../../src/LoadingButton"

import {
  Bed,
  Couch,
  Utensils,
  Bath,
  Chair,
  Home,
  BabyCarriage,
} from "@styled-icons/fa-solid"
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import Countdown from 'react-countdown'
import { UserImages } from "../../src/AI-Generations"
import { EditArrowBack } from '@styled-icons/fluentui-system-regular/EditArrowBack'
import { Library } from '@styled-icons/fluentui-system-filled/Library'
import { Theaters as Theater } from '@styled-icons/material/Theaters'
import { 
  LocalLaundryService, 
  MusicNote, 
  Brush, 
  SportsEsports, 
  FitnessCenter, 
  Kitchen, 
  Storage, 
  Weekend,
  Download, 
  Edit,
} from '@styled-icons/material';
import {Undo, Redo} from '@styled-icons/icomoon'
import { Plant } from '@styled-icons/remix-fill/Plant'
import { Check } from '@styled-icons/boxicons-regular/Check'

import { 
  WineBottle, 
  Book 
} from '@styled-icons/fa-solid';
import Head from 'next/head'
import { aiGenerations } from "../../src/FirebaseApp"
import { BrushableImage } from "../../src/BrushableImage"
import { ImageUpload } from "../../src/ImageUpload"
import MarketingContent from "../../src/MyRoomDesignerMarketingContent"

import StyleLinkList from '../../src/StyleLinkList';

import useUndo from 'use-undo';

import { styles, styleDescriptions } from '../../src/InteriorAIStyles'
import { UndoOutline } from "styled-icons/evaicons-outline"
import {BoardLayout} from '../ai-generator/[[...slug]].js'

const ICON_SIZE = 25

const downloadImage = async (imageUrl, boardId, seoFilename) => {
  try {
    // Fetch the image data
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // Create a local URL for the blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = blobUrl;
    
    // Use seoFilename if available, otherwise use boardId, fallback to a default name
    const filename = seoFilename || `design_${boardId}` || 'interior_design';
    link.download = `${filename}.jpg`;
    
    // Append to body, click, and remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the blob URL
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading image:', error);
    // You might want to show an error message to the user here
  }
};

const roomTypes = [
  {
    name: "Living Room",
    icon: <Couch size={ICON_SIZE} />,
    defaultItems: "artwork, sofa, armchair, storage cabinet",
    url: "living-room",
    title: 'AI Living Room Design | Create Your Dream Space in Seconds',
    description: 'Transform your living room with our AI room design tool. Get instant, personalized ideas for artwork, sofas, armchairs, and storage cabinets. Experience the future of interior design today.',
    heading: 'MyRoomDesigner.ai: Generate Your Own Living Room Design',
  },
  {
    name: "Dining Room",
    icon: <Chair size={ICON_SIZE} />,
    defaultItems: "dining table, chairs, cabinet",
    url: "dining-room",
    title: 'AI Dining Room Design | Instant Ideas for Tables, Chairs & Cabinets',
    description: 'Create your ideal dining room with our AI design generator. Get unique layouts for dining tables, chairs, and cabinets in seconds. Revolutionize your home with AI-powered interior design.',
    heading: 'MyRoomDesigner.ai: Craft Your Dining Room Design',
  },
  {
    name: "Bedroom",
    icon: <Bed size={ICON_SIZE} />,
    defaultItems: "bed, wardrobe, nightstand",
    url: "bedroom",
    title: 'AI Bedroom Design | Personalized Layouts in Seconds',
    description: 'Design your dream bedroom instantly with our AI room planner. Get custom ideas for beds, wardrobes, and nightstands. Experience the power of AI in creating your perfect sleep sanctuary.',
    heading: 'MyRoomDesigner.ai: Design Your Dream Bedroom with AI Room Design',
  },
  {
    name: "Kitchen",
    icon: <Utensils size={ICON_SIZE} />,
    defaultItems: "stove, refrigerator, sink",
    url: "kitchen",
    title: 'AI Kitchen Design | Instant Layout Ideas for Modern Homes',
    description: 'Revolutionize your kitchen with AI-generated designs. Get instant ideas for stove, refrigerator, and sink arrangements. Step into the future of kitchen planning with our AI tool.',
    heading: 'MyRoomDesigner.ai: Compose Your Kitchen Themes with Room Design AI',
  },
  {
    name: "Bathroom",
    icon: <Bath size={ICON_SIZE} />,
    defaultItems: "bathtub, sink, toilet, cabinet",
    url: "bathroom",
    title: "AI Bathroom Design | Instant Ideas for Your Perfect Oasis",
    heading: "Transform Your Bathroom with AI-Powered Design",
    description: "Design your ideal bathroom with AI-generated room designs. Discover the perfect balance of bathtubs, sinks, toilets, and cabinets in your bathroom design.",
},
{
    name: "Home Office",
    icon: <Home size={ICON_SIZE} />,
    defaultItems: "desk, office chair, bookshelf",
    url: "home-office",
    title: "AI Home Office Design | Instant Productivity-Boosting Layouts",
    heading: "Optimize Your Workspace with AI-Generated Office Designs",
    description: "Design a productive home office with AI-generated room designs. Get inspired by setups with desks, office chairs, and bookshelves.",
},
{
    name: "Nursery",
    icon: <BabyCarriage size={ICON_SIZE} />,
    defaultItems: "crib, changing table, dresser",
    url: "nursery",
    title: "AI Nursery Design | Instant Ideas for Your Baby's Room",
    heading: "Create the Perfect Nursery with AI-Powered Room Design",
    description: "Create a loving nursery with AI-generated room designs. Discover a world of cute and practical designs featuring cribs, changing tables, and dressers.",
},

  {
    name: "Yoga Studio",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 inline-block"
       viewBox="0 0 512 512"><g xmlns="http://www.w3.org/2000/svg">
       <path fill="currentColor" d="M482.752,435.574c-6.928-8.1-23.127-40.492-23.127-40.492s2.676-3.448,0-15.051   c-3.48-15.035-18.514-13.886-21.978-17.349c-3.479-3.472-33.549-58.424-35.863-64.792c-2.314-6.369-27.772-78.662-27.772-78.662   c-8.549-37.604-24.308-53.221-45.121-57.85c-20.64-4.581-31.817-3.471-41.075-11.571c-5.778-5.054-5.573-8.809-5.573-24.056   c0,0,6.235-5.927,10.784-14.122c5.195-9.375,7.746-22.907,7.746-22.907c5.211-2.086,5.274-4.684,7.525-12.965   c3.118-11.461,2.897-19.317-5.431-19.317C304.836,19.066,286.085,0,256,0c-30.07,0-48.821,19.066-46.853,56.441   c-8.328,0-8.564,7.856-5.432,19.317c2.251,8.281,2.314,10.879,7.51,12.965c0,0,2.55,13.532,7.762,22.907   c4.55,8.194,10.784,14.122,10.784,14.122c0,15.247,0.189,19.002-5.589,24.056c-9.242,8.1-20.435,6.99-41.059,11.571   c-20.828,4.628-36.572,20.246-45.12,57.85c0,0-25.457,72.294-27.771,78.662c-2.314,6.368-32.401,61.32-35.864,64.792   c-3.464,3.463-18.514,2.314-21.978,17.349c-2.676,11.603,0,15.051,0,15.051s-16.2,32.392-23.143,40.492   c-6.942,8.092,5.794,13.878,13.886,3.464c0.944,1.409,4.156,2.424,7.793,2.912c-28.228,31.251-12.138,71.964,31.55,69.98   C118.291,510.3,256,485.316,256,485.316S393.707,510.3,429.54,511.93c43.688,1.984,59.778-38.729,31.534-69.98   c3.652-0.488,6.864-1.503,7.808-2.912C476.974,449.452,489.695,443.666,482.752,435.574z M183.123,383.849   c0,0-59.274,17.626-96.192,34.234c7.604-14.154,16.357-33.423,16.357-33.423l37.029-53.212l29.504-64.218   c0,0,9.257,34.714,12.138,39.917C184.855,312.35,183.123,383.849,183.123,383.849z M328.891,383.849c0,0-1.732-71.498,1.149-76.702   c2.897-5.203,12.154-39.917,12.154-39.917l29.504,64.218l37.013,53.212c0,0,8.769,19.27,16.373,33.423   C388.165,401.474,328.891,383.849,328.891,383.849z"/>
     </g>
</svg>
    ),
    defaultItems: "yoga mats, blocks, straps, meditation cushions, big windows",
    url: "yoga-studio",
    title: '🧘 Yoga Studio | Yoga Mats, Blocks, Straps, Meditation Cushions, Big Windows Inspirations',
    description: 'Design your dream yoga studio with AI-generated room designs. Get inspiration for yoga mats, blocks, straps, meditation cushions, and big windows. Experience the zen of design.',
    heading: 'Design Your Dream Yoga Studio with Room Design AI',
  },
  {
    name: "Garden",
    icon: <Plant size={ICON_SIZE} />,
    defaultItems: "plants, outdoor furniture, garden tools",
    url: "garden",
    title: '🌳 Garden Designs | Plants, Outdoor Furniture, Garden Tools Inspirations',
    description: 'Craft your perfect garden with AI-generated room designs. Explore ideas for plants, outdoor furniture, and garden tools. Dive into the future of gardening.',
    heading: 'Design Your Dream Garden with Room Design AI',
  },
  {
    name: "Library",
    icon: <Library size={ICON_SIZE} />,
    defaultItems: "books, reading chair, desk, reading lamp",
    url: "library",
    title: '📚 Library Ideas | Books, Reading Chair, Desk, Reading Lamp Inspirations',
    description: 'Create a cozy library with AI-generated room designs. Find ideas for your books, reading chair, desk, and reading lamp. Discover the future of reading room design.',
    heading: 'Design Your Perfect Library with Room Design AI',
  },
  {
    name: "Theater",
    icon: <Theater size={ICON_SIZE} />,
    defaultItems: "projector, comfortable chairs, sound system",
    url: "theater",
    title: '🎥 Home Theater Ideas | Projector, Comfortable Chairs, Sound System Inspirations',
    description: 'Design your home theater with AI-generated room designs. Discover ideas for your projector, comfortable chairs, and sound system. Dive into the future of home cinema design.',
    heading: 'Design Your Home Theater with Room Design AI',
  },
  {
    name: "Studio",
    icon: <Brush size={ICON_SIZE} />,
    defaultItems: "easel, art supplies, good lighting, sink",
    url: "studio",
    title: "Create Amazing Studio",
    heading: "Generate your Dream Studio with AI",
    description: "Explore unlimited studio setup possibilities with our AI-powered interior design generator. Find the perfect harmony between easel, art supplies, good lighting, and sink.",
},
{
    name: "Game Room",
    icon: <SportsEsports size={ICON_SIZE} />,
    defaultItems: "pool table, dart board, board games, seating area",
    url: "game-room",
    title: "Innovative Game Room",
    heading: "Generate Exciting Game Room Ideas with AI",
    description: "Revolutionize your game room design with AI-generated room designs. Discover captivating setups with pool tables, dart boards, board games, and comfortable seating areas.",
},
{
    name: "Wine Cellar",
    icon: <WineBottle size={ICON_SIZE} />,
    defaultItems: "wine racks, wine fridge, tasting table",
    url: "wine-cellar",
    title: "Curate Sophisticated Wine Cellar",
    heading: "Create your Elegant Wine Cellar with AI",
    description: "Design your ideal wine cellar using AI-generated room designs. Uncover stylish concepts featuring wine racks, wine fridges, and tasting tables.",
},
{
    name: "Music Room",
    icon: <MusicNote size={ICON_SIZE} />,
    defaultItems: "piano, guitar stand, music sheets, music stands",
    url: "music-room",
    title: "Compose Harmonious Music Room",
    heading: "Generate your Ideal Music Room with AI",
    description: "Harmonize your music room design with AI-generated room designs. Get inspired by ideas for pianos, guitar stands, music sheets, and music stands.",
},
{
    name: "Fitness Room",
    icon: <FitnessCenter size={ICON_SIZE} />,
    defaultItems: "treadmill, dumbbells, exercise bike, yoga mat",
    url: "fitness-room",
    title: "Craft Energizing Fitness Room",
    heading: "Create your Ultimate Fitness Room with AI",
    description: "Get your fitness room into shape with AI-generated room designs. Discover layouts with treadmills, dumbbells, exercise bikes, and yoga mats.",
},
{
    name: "Pantry",
    icon: <Kitchen size={ICON_SIZE} />,
    defaultItems: "shelves, canned goods, dry goods",
    url: "pantry",
    title: "Develop Organized Pantry",
    heading: "Organize your Pantry with AI Inspiration",
    description: "Plan your perfect pantry with AI-generated room designs. Get inspired by ideas for shelves, canned goods, and dry goods storage.",
},
{
    name: "Laundry Room",
    icon: <LocalLaundryService size={ICON_SIZE} />,
    defaultItems: "washer, dryer, laundry baskets, ironing board",
    url: "laundry-room",
    title: "Design Efficient Laundry Room",
    heading: "Plan your Laundry Room with AI",
    description: "Design your ideal laundry room with AI-generated room designs. Uncover efficient setups with washers, dryers, laundry baskets, and ironing boards.",
},
{
    name: "Mudroom",
    icon: <Home size={ICON_SIZE} />,
    defaultItems: "coat rack, shoe storage, bench",
    url: "mudroom",
    title: "Design Practical Mudroom",
    heading: "Create your Functional Mudroom with AI",
    description: "Design a practical mudroom with AI-generated room designs. Discover setups for coat racks, shoe storage, and benches.",
},
{
    name: "Balcony",
    icon: <Weekend size={ICON_SIZE} />, 
    defaultItems: "outdoor seating, plants, small table",
    url: "balcony",
    title: "Build Tranquil Balcony",
    heading: "Create your Peaceful Balcony with AI",
    description: "Plan your balcony escape with AI-generated room designs. Explore ideas for outdoor seating, plants, and small tables.",
},
{
    name: "Attic",
    icon: <Storage size={ICON_SIZE} />,
    defaultItems: "storage boxes, old furniture",
    url: "attic",
    title: "Create Spacious Attic",
    heading: "Design your Spacious Attic with AI",
    description: "Reimagine your attic with AI-generated room designs. Discover designs for storage boxes and old furniture setups.",
},
{
    name: "Basement",
    icon: <Storage size={ICON_SIZE} />,
    defaultItems: "storage boxes, unused appliances, old furniture",
    url: "basement",
    title: "Plan Functional Basement",
    heading: "Plan your Functional Basement with AI",
    description: "Plan your basement makeover with AI-generated room designs. Explore functional designs for storage boxes, unused appliances, and old furniture.",
},
{
    name: "Closet",
    icon: <Storage size={ICON_SIZE} />,
    defaultItems: "clothes, shoes, accessories",
    url: "closet",
    title: "Craft Organized Closet",
    heading: "Design your Organized Closet with AI",
    description: "Organize your closet with AI-generated room designs. Get inspired by ideas for clothes, shoes, and accessories storage.",
},
{
    name: "Library",
    icon: <Book size={ICON_SIZE} />,
    defaultItems: "books, reading chair, desk, reading lamp",
    url: "library",
    title: "Design Cozy Library",
    heading: "Design your Cozy Library with AI",
    description: "Design a cozy library with AI-generated room designs. Get inspired by ideas for books, a reading chair, a desk, and a reading lamp.",
},
]



const colorCombinations = [
  {
    name: "Neutrals: White, Light Grey, Dark Grey",
    value: ["White", "Light Grey", "Dark Grey"],
  },
  {
    name: "Neutrals: White, Beige, Light Brown",
    value: ["White", "Beige", "Light Brown"],
  },
  { name: "Neutrals: White, Black, Red", value: ["White", "Black", "Red"] },
  {
    name: "Neutrals: White, Off-White, Navy Blue",
    value: ["White", "Off-White", "Navy Blue"],
  },
  {
    name: "Pastels: Light Pink, Light Blue, Light Green",
    value: ["Light Pink", "Light Blue", "Light Green"],
  },
  {
    name: "Pastels: Lavender, Light Pink, Light Grey",
    value: ["Lavender", "Light Pink", "Light Grey"],
  },
  {
    name: "Pastels: Mint Green, Light Yellow, Peach",
    value: ["Mint Green", "Light Yellow", "Peach"],
  },
  {
    name: "Pastels: Light Orange, Light Purple, Light Blue",
    value: ["Light Orange", "Light Purple", "Light Blue"],
  },
  {
    name: "Brights: Yellow, Orange, Pink",
    value: ["Yellow", "Orange", "Pink"],
  },
  { name: "Brights: Red, Orange, Yellow", value: ["Red", "Orange", "Yellow"] },
  { name: "Brights: Blue, Green, Purple", value: ["Blue", "Green", "Purple"] },
  {
    name: "Brights: Pink, Purple, Magenta",
    value: ["Pink", "Purple", "Magenta"],
  },
  {
    name: "Earthy Neutrals: Tan, Beige, Ivory",
    value: ["Tan", "Beige", "Ivory"],
  },
  {
    name: "Luxury Neutrals: Black, Gold, White",
    value: ["Black", "Gold", "White"],
  },
  {
    name: "Soft Pastels: Powder Blue, Soft Pink, Beige",
    value: ["Powder Blue", "Soft Pink", "Beige"],
  },
  {
    name: "Rich Pastels: Teal, Coral, Mauve",
    value: ["Teal", "Coral", "Mauve"],
  },
  {
    name: "Warm Tones: Rust, Terra Cotta, Mustard Yellow",
    value: ["Rust", "Terra Cotta", "Mustard Yellow"],
  },
  {
    name: "Cool Tones: Slate Blue, Grey, Teal",
    value: ["Slate Blue", "Grey", "Teal"],
  },
  {
    name: "Monochromatic: Charcoal Grey, Silver, Dove Grey",
    value: ["Charcoal Grey", "Silver", "Dove Grey"],
  },
  {
    name: "Monochromatic: Rose, Pink, Blush",
    value: ["Rose", "Pink", "Blush"],
  },
  {
    name: "Complementary: Navy Blue, Burnt Orange",
    value: ["Navy Blue", "Burnt Orange"],
  },
  {
    name: "Complementary: Forest Green, Dusty Rose",
    value: ["Forest Green", "Dusty Rose"],
  },
  {
    name: "Complementary: Purple, Mustard Yellow",
    value: ["Purple", "Mustard Yellow"],
  },
  {
    name: "Analogous: Sunset Tones, Red, Orange, Yellow",
    value: ["Red", "Orange", "Yellow"],
  },
  {
    name: "Analogous: Ocean Tones, Blue, Teal, Green",
    value: ["Blue", "Teal", "Green"],
  },
]

const moodBoardTypes = [
  {
    name: "Interior Design AI",
    styles, //styles array defined previously
    roomTypes, //roomTypes array defined previously
    colorCombinations, //colorCombinations array defined previously
    url: 'interior-design',
    title: 'Design Your Dream Room in Seconds | AI-Powered Interior Designer',
    description: "Transform your space effortlessly with My AI Room Designer. Whether you're looking to design your room online or need AI-powered tools for personalized room planning, our intuitive platform lets you create, customize, and perfect your dream room in minutes. Explore options for my room design, interior design, and more with just a few clicks.",
    heading: 'Create Your Own Room Design'
  },
  {
    name: "Fashion",
    styles: ["Boho", "Business Casual", "Street", "Vintage", "Glam"],
    categories: ["Men's", "Women's", "Kids", "Accessories", "Footwear"],
    colorCombinations,
    defaultItems: "shirt, trousers, shoes",
    url: 'fashion',
    title: '👗 AI-Curated Fashion Mood Boards | Men\'s, Women\'s, Kids & Accessories Styles',
    description: 'Discover your style with AI-curated fashion interior designs. From Boho to Glam, explore styles for Men\'s, Women\'s, Kids, and accessories. Reinvent your wardrobe with AI.',
    heading: 'Craft Your Fashion Styles with Room Design AI'
  },
  {
    name: "Wedding",
    styles: ["Romantic", "Vintage", "Modern", "Rustic", "Beach", "Boho", "Classic", "Glam", "Garden"],
    categories: ["Ceremony", "Reception", "Floral Arrangement", "Invitations", "Table Decor", "Bridal Outfit", "Groom Outfit"],
    colorCombinations,
    defaultItems: "wedding bouquet, wedding cake, invitation, venue decoration, bride's dress, groom's suit, accessories, photo corner",
    url: 'wedding',
    title: '💍 AI-Crafted Wedding Mood Boards | Ceremony, Reception & Outfit Inspirations',
    description: 'Plan your dream wedding with AI-crafted interior designs. From the Ceremony to the Reception, explore Romantic to Garden styles. Let AI help create your perfect wedding.',
    heading: 'Design Your Dream Wedding with Room Design AI'
  },
  {
    name: "2023 Trends",
    styles: ["Sustainable Living", "Digital Nostalgia", "Biophilic Design", "Upcycling", "Remote Work Lifestyle", "Mixed Reality", "Craftsmanship", "Global Fusion", "Smart Living", "Eco-Activism", "Wearable Tech", "Minimalist Living", "Artisanal Foods", "Renewable Energy", "Pet Tech"],
    categories: ["Fashion", "Interior Design", "Tech", "Lifestyle"],
    colorCombinations,
    defaultItems: "clothing trends, tech gadgets, sustainable products, interior concepts",
    url: '2023-trends',
    title: '🌟 AI-Generated 2023 Trend Mood Boards | Fashion, Interior, Tech & Lifestyle',
    description: 'Get ahead of the curve with AI-generated interior designs focused on 2023 trends. Explore the future of fashion, interior design, tech, and lifestyle.',
    heading: 'Discover 2023 Trends with AI-Generated Mood Boards'
  },
  {
    name: "Food Branding",
    styles: ["Organic", "Gourmet", "Fast-Casual", "Fine Dining", "Street Food", "Vegan", "Ethnic Cuisine", "Family-Friendly", "Health-Conscious", "Dessert-Focused", "Brunch Spot", "Pet-Friendly", "Food Truck", "Nightlife"],
    categories: ["Packaging", "Menu Design", "Interior Decor", "Ad Concepts", "User Experience"],
    colorCombinations,
    defaultItems: "packaging, menu design, interior decor, ad concepts, user photos",
    url: 'food-branding',
    title: '🍴 AI-Curated Food Branding Mood Boards | Menu, Packaging & Ad Inspirations',
    description: 'Redefine your food brand with AI-curated interior designs. From packaging to user experience, craft a unique brand identity for your restaurant or food product.',
    heading: 'Reimagine Your Food Brand with Room Design AI'
  },
  {
    name: "Food Photography",
    styles: ["Product Shots", "Recipe Steps", "Restaurant Ambiance", "Dish Details", "Ingredients", "Culinary Techniques", "Chef Portraits", "Seasonal Specials", "Behind-the-Scenes", "Holiday Themes", "Cultural Cuisine", "Experimental Gastronomy", "Kids Meals", "Beverage Close-Ups"],
    colorCombinations,
    defaultItems: "main course photos, dessert snaps, appetizer visuals, drink images, kitchen process",
    url: 'food-photography',
    title: '🥙 AI-Curated Food Photography Mood Boards | Main Course, Desserts, Drinks & More',
    description: 'Elevate your food photography with AI-generated interior designs. Capture delicious moments from main courses to desserts, all in the perfect light and setting.',
    heading: 'Revamp Your Food Photography with AI-Generated Mood Boards'
  },
  {
    name: "Restaurant Photography",
    styles: ["Fine Dining", "Casual Dining", "Cafe", "Street Stall", "Bar & Grill", "Farm-to-Table", "Seafood", "Steakhouse", "Bakery", "Catering Events", "Themed Dining", "Live Music Venues", "Waterfront", "Buffet Layout", "Rooftop Settings"],
    categories: ["Interior", "Dishes", "Staff", "Guests", "Cooking Process"],
    colorCombinations,
    defaultItems: "interior shots, dish presentation, staff portraits, guests enjoying, cooking shots",
    url: 'restaurant-photography',
    title: '🍽️ AI-Inspired Restaurant Photography Mood Boards | Interior, Dishes & Guest Shots',
    description: "Elevate your restaurant's image with AI-inspired photography interior designs. Capture the essence of your establishment, from the interior to the dishes.",
    heading: "Capture Your Restaurant's Essence with Room Design AI"
  },
  {
    name: "Photography",
    styles: ["Portrait", "Landscape", "Macro", "Black and White", "Street", "Aerial", "Event"],
    categories: ["Nature", "People", "Urban", "Abstract", "Fashion"],
    colorCombinations,
    defaultItems: "landscape, portrait, macro",
    url: 'photography',
    title: '📸 AI-Inspired Photography Mood Boards | Nature, People, Urban & Abstract Categories',
    description: 'Discover AI-inspired photography interior designs. From Portrait to Aerial, explore nature, people, urban, and abstract categories. Unleash your creativity with AI.',
    heading: 'Compose Stunning Photography Themes with Room Design AI'
  },
]

const countDownRenderer = ({ seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <div></div>
  } else {
    // Render a countdown
    return <div className="text-pink-800">{seconds} seconds</div>
  }
}

const CountdownTimer = () => {
  const [time, setTime] = useState(Date.now() + 45000)

  useEffect(() => {
    setTime(Date.now() + 45000)
  }, [])

  return <Countdown date={time} renderer={countDownRenderer} />
}


const MoodBoardAI = props => {
  const router = useRouter()
  const { slug } = router.query
  let moodBoardTypeName, roomTypeName, style

  if (slug?.length > 0) {
    const result = getMoodBoardAndRoomTypeFromURL(
      slug[0],
      moodBoardTypes,
      roomTypes,
      styles,
    )
    moodBoardTypeName = result.moodBoardTypeName
    roomTypeName = result.roomTypeName
    if(result.style) style = result.style
  }
  const [selectedMoodBoardType, setSelectedMoodBoardType] = useState(moodBoardTypeName ? moodBoardTypes.find(type => type.name === moodBoardTypeName) : moodBoardTypes[0])
  const [selectedRoom, setSelectedRoom] = useState(roomTypeName ? roomTypes.find(room => room.name === roomTypeName).name : roomTypes[0].name)
  const [selectedStyle, setSelectedStyle] = useState(style || styles[0])
  const [selectedColor, setSelectedColor] = useState("")
  const defaultItems = selectedMoodBoardType?.defaultItems || roomTypes[0].defaultItems;
  const [
    customInputState,
    { set: setCustomInput, undo, redo, canUndo, canRedo }
  ] = useUndo(defaultItems)
  const customInput = customInputState.present
  const [customInputValue, setCustomInputValue] = useState(null)
  const [isCustomInputFocused, setIsCustomInputFocused] = useState(false)
  const [moodBoard, setMoodBoard] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = useState(false)
  const fb = useContext(FirebaseContext)
  const { app, user, createBoard, isAdmin, stripeRole } = fb
  const redirect = useRedirectToProfile()
  const { t } = useTranslation()
  const [loadingPercentage, setLoadingPercentage] = useState("0%")
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [showCustomColorInput, setShowCustomColorInput] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [isBrushActive, setIsBrushActive] = useState(false)
  const [imageUpload, setImageUpload] = useState(null)
  const [isRewriting, setIsRewriting] = useState(false);
  const [customPrompt, setCustomPrompt] = useState("");
  const debounceTimerRef = useRef(null)
  const [layoutConditions, setLayoutConditions] = useState(null);
  const outputRef = useRef(null);

  const moodBoardOptions = [
    { 
      name: "Inspiration", 
      description: "Discover designs inspired by your image" 
    },
    { 
      name: "Restyling", 
      description: "Transform your room with a new look", 
      isNew: true 
    }
  ]
  const [selectedRenderingOption, setSelectedRenderingOption] = useState(moodBoardOptions[1].name)

  useEffect(() => {
    if(props.setType && selectedMoodBoardType) props.setType(selectedMoodBoardType.name)
    if(props.setRoomType && selectedRoom) props.setRoomType(selectedRoom)
    if(props.setStyle) props.setStyle(selectedStyle)
  }, [selectedMoodBoardType, selectedRoom, selectedStyle])

  useEffect(() => {
    const loadMoodBoard = async () => {
      if (!app || !slug) return

      // Check if the slug starts with "b/"
      if (slug[0] === "b" && slug.length > 1) {
        let documentID = slug[1] // The ID follows after "b/"

        try {
          const aiRef = await aiGenerations()
          const docSnapshot = await aiRef.doc(documentID).get()
          let fetchedData
          
          if (docSnapshot.exists) {
            fetchedData = docSnapshot.data()
          } else {
            //load from seo_url
            const boardDataFromSeoUrl = await aiRef.where("seo_url", "==", slug[1]).get()
            if (boardDataFromSeoUrl.size > 0) {
              fetchedData = boardDataFromSeoUrl.docs[0].data()
              documentID = boardDataFromSeoUrl.docs[0].id
            }
          }
          if(fetchedData){
            console.log(fetchedData, user?.uid, fetchedData.uid)
            setMoodBoard({...fetchedData, imageUrl: fetchedData.upscaledImageUrl || fetchedData.image, id: documentID}) 
            if(fetchedData.type) setSelectedMoodBoardType(moodBoardTypes.find(type => type.name === fetchedData.type))
            if(fetchedData.roomType) setSelectedRoom(fetchedData.roomType)
            if(fetchedData.style) setSelectedStyle(fetchedData.style)
            if(fetchedData.colorCombination) setSelectedColor(fetchedData.colorCombination)
            // if(fetchedData.detections) setDetections(fetchedData.detections)
          }
        } catch (err) {
          console.error("Error fetching specific document from Firestore:", err)
        }
      }
    }
  
    
    loadMoodBoard()
  }, [slug, app])
  
  useEffect(() => {
    if(!user || !moodBoard) return
    if(user.uid === moodBoard.uid) {
      setIsOwner(true)
      props.setIsOwner(true)
      if(moodBoard.customInput) {
        setCustomInput(moodBoard.customInput)
        setCustomInputValue(moodBoard.customInput)
      }
    } else {
      setIsOwner(false)
      props.setIsOwner(false)
    }
  }, [user, moodBoard])


  const handleChange = (setFunction, value) => {
    const hasValidAccess = stripeRole || isAdmin
    
    if (!userIsLoggedIn || !hasValidAccess) {
      setIsDialogOpen(true)
      return
    }
    setFunction(value)
  }

  const editInNewBoard = async (image, id) => {
    if (typeof window !== "undefined") {
      const createCanvasImgObject = (
        await import("../../src/createCanvasImgObject")
      ).default
      const canvasImgObject = await createCanvasImgObject(image, id)
      const boardId = await createBoard({}, { objects: [canvasImgObject] })
      if (boardId instanceof Error) {
        console.error(boardId)
        return
      } else if (boardId) router.push(`/edit/${boardId}`)
    }
  }
  


  const getStatusText = (status) => {
    if (!status) return "";
    
    // Check if the status contains Chinese characters
    if (/[\u4e00-\u9fa5]/.test(status)) {
      return ""; // Return empty string if Chinese characters are found
    }
    
    // Convert to lowercase and replace underscores with spaces
    const formattedStatus = status.toLowerCase().replace(/_/g, ' ');
    
    // Capitalize the first letter of each word
    return formattedStatus.replace(/\b\w/g, char => char.toUpperCase());
  };

  const sleep = ms => new Promise(r => setTimeout(r, ms))
  const generateMoodBoardAi = async params => {
    await import("firebase/functions")
    const generateMoodBoardAi = app.functions().httpsCallable("generateMoodBoardAi")
    const controlnetSdxl = app.functions().httpsCallable("controlnetSdxl")
    const virtualStaging = app.functions().httpsCallable("virtualStaging")
    const fluxHyperReplicateRestyling = app.functions().httpsCallable("FluxHyperReplicateRestyling")
  
    try {
      let functionToCall
      let variations = []
      let docId
  
      // Check if imageUpload is not set
      if (!params.imageUpload) {
        // Default to Inspiration if imageUpload is not set
        functionToCall = generateMoodBoardAi
        variations = [params] // Single variation for Inspiration
      } else {
        // If imageUpload is set, use the selected rendering option
        switch (selectedRenderingOption) {
          case "Inspiration":
            functionToCall = generateMoodBoardAi
            variations = [params] // Single variation for Inspiration
            break
          case "Restyling":
            // Create variations for both functions
            const fluxVariations = params.guidanceScales.map((guidanceScale, index) => ({
              ...params,
              controlStrength: params.controlStrengths[index],
              functionName: "flux" 
            }))
            
            const controlnetVariations = params.guidanceScales.map((guidanceScale, index) => ({
              ...params,
              guidanceScale: params.guidanceScales[index],
              controlStrength: params.controlStrengths[index],
              imageToImageStrength: params.imageToImageStrengths[index],
              controlType: params.controlType[index],
              functionName: "controlnet"
            }))
            functionToCall = controlnetSdxl
            variations = [...controlnetVariations]
            // variations = [...fluxVariations, ...controlnetVariations]
            break
          case "Virtual Staging":
            functionToCall = virtualStaging
            variations = [params] // Single variation for Virtual Staging
            break
          default:
            throw new Error("Invalid rendering option")
        }
      }

      const MAX_RETRIES = 3;
      const RETRY_DELAY = 1000; 
      const retryWithDelay = async (fn, retries = MAX_RETRIES, delay = RETRY_DELAY) => {
        try {
          return await fn();
        } catch (error) {
          if (error.code === "resource-exhausted") {
            setError({ code: "resource-exhausted" });
            setIsLoading(false);
            return;
          }
          if (retries <= 0) throw error;
          await new Promise(resolve => setTimeout(resolve, delay));
          return retryWithDelay(fn, retries - 1, delay * 2);
        }
      };
  
      const promises = variations.map(async (variation, index) => {
        if (index > 0) await sleep(500); // 500ms pause between requests
        
        try {
          const result = await retryWithDelay(async () => {
            // Choose which function to call based on the variation
            let response
            if (variation.functionName === "flux") {
              response = await fluxHyperReplicateRestyling({ ...variation, groupId: params.groupId })
            } else if (variation.functionName === "controlnet") {
              response = await controlnetSdxl({ ...variation, groupId: params.groupId })
            } else {
              response = await functionToCall({ ...variation, groupId: params.groupId })
            }
            
            if (functionToCall !== fluxHyperReplicateRestyling && functionToCall !== controlnetSdxl) {
              docId = response.data.id; // Store the returned document ID
            }
            return response;
          });
          return { success: true, data: result };
        } catch (error) {
          console.error(`Failed to process variation ${index} after ${MAX_RETRIES} retries:`, error);
          return { success: false, error, index };
        }
      });
      await Promise.all(promises)
  
      // Set up a snapshot listener for real-time updates
      const aiRef = await aiGenerations()
      let unsubscribe
  
      if (functionToCall === fluxHyperReplicateRestyling || functionToCall === controlnetSdxl) {
        // For Restyling, use groupId to listen for multiple variations
        unsubscribe = aiRef.where("groupId", "==", params.groupId).onSnapshot(
          snapshot => {
            const updatedVariations = []
            snapshot.forEach(doc => {
              const data = doc.data()
              updatedVariations.push({ ...data, id: doc.id })
            })
  
            if (updatedVariations.length > 0) {
              setMoodBoard(prevState => ({
                ...prevState,
                groupId: params.groupId,
                variations: updatedVariations,
              }))
            }
  
            // Check if all variations are complete
            const allComplete = updatedVariations.every(
              v => v.status === "SUCCESS" || v.status === "FAILURE",
            )
            if (allComplete) {
              setIsLoading(false)
              setLoadingPercentage("0%")
              unsubscribe() // Stop listening when all processes are complete
            } else {
              // Update loading percentage based on completed variations
              const completedCount = updatedVariations.filter(
                v => v.status === "SUCCESS" || v.status === "FAILURE",
              ).length
              const totalCount = variations.length
              const percentage = Math.round((completedCount / totalCount) * 100)
              setLoadingPercentage(`${percentage}%`)
            }
          },
          error => {
            console.error("Error setting up snapshot listener:", error)
            setError("Error generating interior design. Please retry.")
            setIsLoading(false)
          },
        )
      } else {
        // For Inspiration and Virtual Staging, listen to a single document
        unsubscribe = aiRef.doc(docId).onSnapshot(
          doc => {
            if (doc.exists) {
              const data = doc.data()
              handlePredictionResponse({ ...data, id: doc.id }, params, params.imageUpload ? selectedRenderingOption : "Inspiration")
              
              if (data.status === "SUCCESS" || data.status === "FAILURE") {
                setIsLoading(false)
                setLoadingPercentage("0%")
                unsubscribe() // Stop listening when the process is complete
              }
            }
          },
          error => {
            console.error("Error setting up snapshot listener:", error)
            setError("Error generating interior design. Please retry.")
            setIsLoading(false)
          },
        )
      }
  
      // Clean up the listener when the component unmounts
      return unsubscribe
    } catch (e) {
      console.log(e)
      setError(e)
      setIsLoading(false)
    }
  }
  const handlePredictionResponse = (
    prediction,
    params,
    selectedRenderingOption,
  ) => {
    setLoadingPercentage(prediction.percentage)
    setMoodBoard({
      ...prediction,
      imageUrl: prediction.image || prediction.imageUrl,
      isStartBrushing:
        params.upscaleId ||
        selectedRenderingOption === "Restyling" ||
        selectedRenderingOption === "Virtual Staging",
    })
    if (prediction.status === "SUCCESS") {
      setIsLoading(false)
      setLoadingPercentage("0%")
    } else if (prediction.status === "FAILURE") {
      setIsLoading(false)
      setLoadingPercentage("0%")
      setError("Error generating interior design. Please retry.")
    }
  }

  const startLoading = () => {
    setIsLoading(true)
    setError(false)
    setMoodBoard(null)
    // Smooth scroll to output area on mobile
    setTimeout(() => {
      if (window.innerWidth < 1024) { // lg breakpoint
        const yOffset = -50; // Add negative offset to scroll higher
        const element = outputRef.current;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100);
  }

  const userIsLoggedIn = user && !user.isAnonymous

  const submitHandler = async () => {
    if (!userIsLoggedIn) {
      redirect()
      return
    }
    startLoading()
    const groupId = `group-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    const moodBoardData = {
      type: selectedMoodBoardType?.name,
      roomType: selectedRoom,
      style: selectedStyle,
      colorCombination: selectedColor,
      texture: "",
      controlType:
        layoutConditions?.isUnfurnished || layoutConditions?.isConstruction
          ? ["canny", "canny", "depth"]
          : ["depth", "depth", "depth"],
      guidanceScales:
        layoutConditions?.isUnfurnished || layoutConditions?.isConstruction
          ? [2.5, 3.5, 4.5]
          : [2.5, 3.5, 4.5],
      controlStrengths:
        layoutConditions?.isUnfurnished || layoutConditions?.isConstruction
          ? [0.4, 0.5, 0.6]
          : [0.55, 0.65, 0.8],
      imageToImageStrengths:
        layoutConditions?.isUnfurnished || layoutConditions?.isConstruction
          ? [0, 0, 0]
          : [0.05, 0.1, 0.15],
      groupId: groupId,
    }
  
    moodBoardData.customInput = customInput;
    
    if (imageUpload != null) {
      moodBoardData.imageUpload = imageUpload;
    }
    
    generateMoodBoardAi(moodBoardData);
  }

  const onChangeSelectedRoom = (room) => {
    setSelectedRoom(room)
    const selectedRoomType = roomTypes.find(r => r.name === room);
    setCustomInput(selectedRoomType ? selectedRoomType.defaultItems : '');
  }

  const handleFinish = async (selectedImage, imageHistory) => {
    try {
      // Check if the selected image is different from the current imageUrl
      if (selectedImage !== moodBoard.imageUrl) {
        // Convert data URL to blob for upload
        const response = await fetch(selectedImage)
        const blob = await response.blob()

        // Create a storage ref with moodBoard.id and date
        await import("firebase/storage")
        const storageRef = app.storage().ref()
        const fileName = `moodboard-${moodBoard.id}-${new Date().getTime()}`
        const imageRef = storageRef.child(`ai-generator-edits/${fileName}`)

        // Upload the blob
        const snapshot = await imageRef.put(blob)

        // Get the URL of the uploaded image
        const storageUrl = await snapshot.ref.getDownloadURL()

        // Update the mood board state with the new image URL
        setMoodBoard(prevMoodBoard => ({
          ...prevMoodBoard,
          imageUrl: storageUrl,
        }))

        // Update the Firestore document with the new image URL
        const aiRef = await aiGenerations()
        await aiRef.doc(moodBoard.id).update({
          image: storageUrl,
        })
      }

      // Update the history in both state and Firestore
      setMoodBoard(prevMoodBoard => ({
        ...prevMoodBoard,
        history: imageHistory,
      }))
      const aiRef = await aiGenerations()
      await aiRef.doc(moodBoard.id).update({
        imageHistory: imageHistory,
      })
    } catch (error) {
      console.error("Error in handleFinish:", error)
      // Handle any errors here
    }
  }

  const handleUpscale = useCallback(async () => {
    if (!moodBoard || !moodBoard.id) return;
    try {
      await import("firebase/functions")
      const upscaleFunction = app.functions().httpsCallable('upscaleImage');
      const result = await upscaleFunction({ boardId: moodBoard.id });
      console.log('Upscale initiated:', result);
      const boardRef = app.firestore().collection('ai-generator').doc(moodBoard.id);
      const unsubscribe = boardRef.onSnapshot((doc) => {
        const data = doc.data();
        if (data) {
          setMoodBoard(prevState => ({
            ...prevState,
            ...data,
            id: moodBoard.id,
            imageUrl: data.upscaledImageUrl || data.image
          }));
          
          if (data.upscaledImageUrl) {
            unsubscribe(); // Stop listening when upscaling is complete
          }
        }
      }, (error) => {
        console.error("Error setting up snapshot listener:", error);
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    } catch (error) {
      console.error('Error initiating upscale:', error);
      setError('Failed to initiate upscale. Please try again.');
    } 
  }, [moodBoard, app]);
  
  const rewriteDescription = async (userInput, existingDescription) => {
    const rewriteUserInput = app
      .functions()
      .httpsCallable("rewriteUserInput")

    setIsRewriting(true)
    try {
      const result = await rewriteUserInput({ userInput, existingDescription })
      return result.data.rewrittenDescription
    } catch (error) {
      console.error("Error rewriting description:", error)
      if (error.code === "unauthenticated") {
        setError("You must be logged in to use this feature.")
      } else if (error.code === "resource-exhausted") {
        setError(
          "You have reached your AI generation limit. Please upgrade your plan.",
        )
      } else {
        setError("An error occurred. Please try again later.")
      }
    } finally {
      setIsRewriting(false)
    }
  }

  const handleUndo = () => {
    undo()
    setCustomInputValue(customInputState.past[customInputState.past.length - 1])
  }

  const handleRedo = () => {
    redo()
    setCustomInputValue(customInputState.future[0])
  }

  const createSimilar = async variation => {
    startLoading()
    const groupId = `group-${Date.now()}-${Math.random()
      .toString(36)
      .substr(2, 9)}`
    await generateMoodBoardAi({
      type: variation.type,
      roomType: variation.roomType,
      style: variation.style,
      colorCombination: variation.colorCombination,
      texture: variation.texture || "",
      customInput: variation.customInput,
      guidanceScales: [3.5, 4, 4.5],
      controlStrengths: [0.6, 0.7, 0.8],
      imageToImageStrengths: [0.15, 0.25, 0.3],
      controlType: ["depth", "depth", "depth"],
      groupId: groupId,
      imageUpload: variation.image,
    })
  }

  return ( 
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Main Content (Left side) */}
      <div ref={outputRef} className="flex-1 flex flex-col justify-start items-center border-r border-t border-b bg-[#fdfdfc] py-4 order-2 lg:order-1">
        {!moodBoard && !isLoading ? (
          <div className="w-full max-w-5xl px-4">
            {/* Hero Section */}
            <div className="relative h-[70vh] flex flex-col items-center justify-center mb-12">
              {/* Background Image with Overlay */}
              <div className="absolute inset-0 z-0">
                <img 
                  src={props.images ? JSON.parse(props.images)[0]?.image : "/assets/ai/restyle-room-demo.jpg"}
                  alt="Latest AI Generated Room Design"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-white/95 via-white/90 to-white/95"></div>
              </div>

              {/* Content */}
              <div className="relative z-10 text-center max-w-3xl mx-auto px-4">
                <h1 className="font-serif text-4xl sm:text-5xl font-medium mb-6 text-gray-900">
                  {props.heading}
                </h1>
                {!props.isStartpage && (
                  <p className="leading-relaxed text-gray-600 mb-8 max-w-2xl mx-auto">
                    {props.description}
                  </p>
                )}
              </div>
            </div>

            {/* Latest Designs Section */}
            <div className="w-full">
              <div className="flex items-center justify-between mb-8">
                <h2 className="text-2xl font-medium text-gray-900 font-heading">
                  Latest Inspirations
                </h2>
                <a href="/interior-design-ai" className="text-pink-700 hover:text-pink-600">
                  View all →
                </a>
              </div>
              <UserImages 
                pageSize={4} 
                images={props.images ? JSON.parse(props.images) : []} 
                type={props.type} 
                roomType={props.roomType} 
                style={props.style} 
                linkName={'interior-design-ai'}
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
              />
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center">
            {/* Only show loading when there are no variations */}
            {isLoading && (!moodBoard?.variations || moodBoard?.variations?.length === 0) && (
              <div className="w-full h-full flex items-center justify-center bg-white bg-opacity-50">
                <Loading percentage={loadingPercentage} />
              </div>
            )}

            {/* Variations Grid */}
            {moodBoard && moodBoard.groupId && moodBoard.variations && moodBoard.variations.length > 0 ? (
              <div className="w-full max-w-4xl mx-auto p-4">
                <div className={`grid grid-cols-1 ${moodBoard.variations.length > 1 ? 'sm:grid-cols-2' : ''} gap-4`}>
                  {moodBoard.variations.map((variation, index) => (
                    <div key={variation.id} className="relative group">
                      {variation.image ? (
                        <img 
                          src={variation.image} 
                          alt={`Result ${index + 1}`} 
                          className="w-full h-auto rounded-lg shadow object-cover"
                        />
                      ) : (
                        <div className="w-full h-0 pb-[100%] bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg flex items-center justify-center overflow-hidden">
                          <div className="absolute inset-0 flex flex-col items-center justify-center">
                            <div className="w-12 h-12"><Loading /></div>
                            <p className="text-gray-700 font-semibold text-lg">Generating...</p>
                          </div>
                        </div>
                      )}
                      {variation.status === "SUCCESS" && (
                        <div className="hidden group-hover:flex justify-center gap-4 absolute left-0 bottom-0 right-0 p-4 bg-white/20 backdrop-blur rounded-b-xl">
                          <button
                            onClick={() => {
                              setMoodBoard(prevMoodBoard => ({
                                ...prevMoodBoard,
                                ...variation,
                                imageUrl: variation.image,
                                variations: null, 
                                isGrid: false
                              }));
                            }}
                            className="bg-pink-800 text-white p-4 rounded-md shadow hover:bg-pink-700"
                          >
                            Choose
                          </button>
                          <button
                            onClick={() => createSimilar(variation)}
                            className="bg-white p-4 rounded-md shadow text-black hover:outline hover:outline-pink-400"
                          >
                            Create similar
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : moodBoard?.imageUrl && !moodBoard.isGrid ? (
              <div className="w-full max-w-4xl mx-auto p-4">
                <BrushableImage
                  src={moodBoard.imageUrl}
                  isOwner={isOwner}
                  className="w-full object-cover rounded-lg"
                  onFinish={handleFinish}
                  isStartBrushing={moodBoard.isStartBrushing}
                  isLoading={isLoading}
                  setIsBrushActiveParent={setIsBrushActive}
                />
                
                {!isBrushActive && isOwner && (
                  <div className="flex justify-center gap-4 mt-4 flex-wrap">
                    <LoadingButton onClick={handleUpscale} highlight disabled={isLoading || moodBoard?.isUpscaling}>
                      {moodBoard?.isUpscaling ? 'Upscaling...' : 'Upscale'}
                    </LoadingButton>
                    <LoadingButton onClick={async () => await editInNewBoard(moodBoard.imageUrl, moodBoard.id)} highlight>
                      Add to designer
                    </LoadingButton>
                    <LoadingButton onClick={() => createSimilar(moodBoard)} highlight>
                      Create similar
                    </LoadingButton>
                    <LoadingButton onClick={() => downloadImage(moodBoard.imageUrl, moodBoard.id, moodBoard.seoFilename)} highlight>
                      <Download className="mr-1 h-4" />
                      Download
                    </LoadingButton>
                    <Button href={`https://lens.google.com/uploadbyurl?url=${encodeURIComponent(moodBoard.imageUrl)}`} target="_blank" rel="noopener noreferrer" highlight>
                      Find similar products
                    </Button>
                  </div>
                )}
              </div>
            ) : moodBoard?.imageUrl && moodBoard?.isGrid && (
              <div className="w-full max-w-4xl mx-auto p-4 aspect-square">
                <div className="grid grid-cols-2 grid-rows-2 gap-4 w-full h-full min-h-max">
                  {Array.from({ length: 4 }).map((_, index) => {
                    const positions = [
                    { backgroundPosition: 'top left' },
                    { backgroundPosition: 'top right' },
                    { backgroundPosition: 'bottom left' },
                    { backgroundPosition: 'bottom right' },
                  ];
                  
                  return (
                    <a
                      key={index}
                      style={{
                        backgroundImage: `url(${moodBoard.imageUrl})`,
                        backgroundSize: "200%",
                        backgroundPosition: positions[index].backgroundPosition,
                      }}
                      className="relative group object-cover rounded-xl w-full h-full hover:ring-4 hover:ring-pink-800 hover:shadow-lg"
                    >
                      <div className="hidden group-hover:flex justify-center gap-2 absolute left-0 bottom-0 right-0 p-3 bg-white/20 backdrop-blur rounded-b-xl sm:text-sm text-xs">
                        <button
                          onClick={() => {
                            startLoading()
                            generateMoodBoardAi({
                              upscaleId: moodBoard.id,
                              upscaleIndex: index + 1,
                              type: selectedMoodBoardType?.name,
                              roomType: selectedRoom,
                              style: selectedStyle,
                              colorCombination: selectedColor,
                              texture: "",
                              customInput,
                            })
                          }}
                          className="bg-pink-800 text-white p-2 rounded shadow hover:bg-pink-700"
                        >
                          Choose
                        </button>
                        <button
                          onClick={() => {
                            startLoading()
                            generateMoodBoardAi({
                              similiarId: moodBoard.id,
                              similiarIndex: index + 1,
                              type: selectedMoodBoardType?.name,
                              roomType: selectedRoom,
                              style: selectedStyle,
                              colorCombination: selectedColor,
                              texture: "",
                              customInput,
                            })
                          }}
                          className="bg-white p-2 rounded shadow text-black hover:outline hover:outline-pink-400"
                        >
                          Create similar
                        </button>
                      </div>
                    </a>
                  )
                })}
              </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Right Navigation Section */}
      <div className="w-full lg:w-1/3 ml-0 lg:ml-6 lg:mt-0 border bg-[#fdfdfc] relative order-1 lg:order-2">
        {/* Main content wrapper */}
        <div className="flex flex-col gap-2 p-4 pb-24">
          {/* Step 1: Design Details */}
          <div className="flex items-center mt-2 mb-2">
            <div className="flex justify-center items-center font-heading rounded-full mr-4 text-lg h-10 w-10 bg-pink-600 text-white">1</div>
            <h2 className="font-heading text-gray-900 text-xl tracking-wide">Choose your design details</h2>
          </div>

          {/* Image Upload */}
          <ImageUpload 
            onImageAdded={setImageUpload} 
            handleChange={handleChange} 
            isOwner={isOwner} 
            moodBoard={moodBoard}
            setCustomInputValue={setCustomInputValue}
            setCustomInput={setCustomInput}
            setIsRewriting={setIsRewriting}
            setIsCustomInputFocused={setIsCustomInputFocused}
            setLayoutConditions={setLayoutConditions}
          />

          {/* Rendering Options */}
          {imageUpload && (
            <div className="mb-2">
              <span className="text-gray-600 text-left block">Choose your rendering style</span>
              <div className="grid grid-cols-2 gap-2 mt-2">
                {moodBoardOptions.map((option) => (
                  <button
                    key={option.name}
                    onClick={() => setSelectedRenderingOption(option.name)}
                    className={`${
                      selectedRenderingOption === option.name 
                        ? "bg-pink-100 hover:bg-pink-200" 
                        : "bg-[#f7f6f4] hover:bg-gray-100"
                    } py-4 px-6 rounded-lg text-center transition duration-300`}
                  >
                    <div className="font-medium text-gray-900 flex items-center justify-center gap-2">
                      {option.name}
                    </div>
                    <p className="text-sm text-gray-500 mt-1 hidden sm:block">
                      {option.description}
                    </p>
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Room Types */}
          {selectedMoodBoardType?.roomTypes && (
            <div className="mb-2">
              <span className="text-gray-600 text-left block">What type of room are you designing?</span>
              <div className="flex overflow-x-auto gap-2 mt-2">
                {selectedMoodBoardType.roomTypes.map((room, index) => (
                  <a
                    key={index}
                    href={`/interior-design-ai/${room.url}`}
                    onClick={event => {
                      event.preventDefault();
                      onChangeSelectedRoom(room.name);
                    }}
                    className={`flex-shrink-0 ${
                      selectedRoom === room.name 
                        ? "bg-pink-100 hover:bg-pink-200" 
                        : "bg-[#f7f6f4] hover:bg-gray-100"
                    } py-4 px-6 rounded-lg text-center transition duration-300`}
                  >
                    {room.name}
                  </a>
                ))}
              </div>
            </div>
          )}

          {/* Style and Color Selectors */}
          <div className="flex flex-col gap-2">
            {selectedMoodBoardType?.styles && (
              <select
                className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-0 focus:ring focus:bg-white focus:ring-pink-500 hover:text-pink-700"
                value={selectedStyle}
                onChange={e => setSelectedStyle(e.target.value)}
              >
                <option value="">Styles</option>
                {selectedMoodBoardType.styles.map((style, index) => (
                  <option key={index} value={style}>
                    {style}
                  </option>
                ))}
              </select>
            )}

            {showCustomColorInput ? (
              <div className="w-full flex items-center gap-2 hover:text-pink-700">
                <input
                  type="text"
                  value={selectedColor}
                  placeholder="Color"
                  onChange={e => handleChange(setSelectedColor,e.target.value)}
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:ring focus:bg-white focus:ring-pink-500 hover:text-pink-700"
                />
                <EditArrowBack className="h-6 w-6 cursor-pointer" onClick={() => setShowCustomColorInput(false)} />
              </div>
            ) : (
              <select
                className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:ring focus:bg-white focus:ring-pink-500 hover:text-pink-700"
                value={selectedColor}
                onChange={e => {
                  if (e.target.value === "custom") {
                    setShowCustomColorInput(true);
                  } else {
                    handleChange(setSelectedColor, e.target.value);
                  }
                }}
              >
                <option value="">Colors</option>
                {colorCombinations.map((color, index) => (
                  <option key={index} value={color.value}>
                    {color.name}
                  </option>
                ))}
                <option value="custom">Custom color</option>
              </select>
            )}
          </div>

          {/* Step 2: Changes Description */}
          <div className="flex items-center mt-6 mb-2">
            <div className="flex justify-center items-center font-heading rounded-full mr-4 w-10 h-10 text-lg bg-pink-600 text-white">2</div>
            <h2 className="font-heading text-gray-900 text-xl tracking-wide">What changes would you like?</h2>
          </div>

          {/* Custom Input Area with Undo/Redo */}
          {isCustomInputFocused && (
            <div className="flex items-center gap-2 sm:col-span-2">
              <div className="flex items-center gap-2 text-pink-800 hover:text-pink-700">
                <button
                  onClick={handleUndo}
                  disabled={!canUndo}
                  className="p-2 h-10 w-10 rounded-full bg-pink-100 hover:bg-pink-200 disabled:opacity-50"
                  title="Undo"
                >
                  <Undo size={20} />
                </button>
                <button
                  onClick={handleRedo}
                  disabled={!canRedo}
                  className="p-2 h-10 w-10 rounded-full bg-pink-100 hover:bg-pink-200 disabled:opacity-50"
                  title="Redo"
                >
                  <Redo size={20} />
                </button>
              </div>
              <div className="flex flex-col sm:flex-row gap-2 w-full">
                <input
                  type="text"
                  placeholder="Describe changes..."
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                  disabled={isRewriting}
                  data-hj-allow=""
                />
                <button
                  onClick={async () => {
                    const rewrittenDescription = await rewriteDescription(customPrompt, customInput);
                    if (rewrittenDescription) {
                      setCustomInput(rewrittenDescription);
                      setCustomInputValue(rewrittenDescription);
                      setCustomPrompt('');
                    }
                  }}
                  className="whitespace-nowrap bg-pink-800 text-white px-4 py-2 rounded-md hover:bg-pink-700 transition-colors disabled:bg-gray-400"
                  disabled={isRewriting || !customPrompt.trim()}
                >
                  {isRewriting ? 'Rewriting...' : 'Change prompt'}
                </button>
              </div>
            </div>
          )}

          {/* Text Input Area */}
          <textarea
            placeholder={customInput}
            onFocus={() => setIsCustomInputFocused(true)}
            value={customInputValue}
            onChange={(e) => {
              const newValue = e.target.value
              setCustomInputValue(newValue)
              
              if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current)
              }
              
              debounceTimerRef.current = setTimeout(() => {
                handleChange(setCustomInput, newValue)
              }, 1000)
            }}
            rows={isCustomInputFocused ? "7" : "1"}
            className="w-full p-4 rounded-lg focus:outline-none border placeholder:text-gray-400 border-gray-200 overflow-y-auto focus:ring-1 focus:ring-pink-200 opacity-80 h-32 bg-[#f7f6f4]"
            data-hj-allow=""
          />
        </div>

        {/* Sticky Generate Button */}
        <div className="sticky bottom-0 right-0 p-4 bg-[#fdfdfc]">
          <div className="flex justify-end">
            <button
              className={`flex items-center justify-center gap-2 transition duration-300 px-6 bg-pink-700 text-white rounded-lg hover:bg-pink-600 py-4 w-64 disabled:opacity-70 disabled:cursor-not-allowed ${isLoading ? 'animate-[pulse_1.5s_ease-in-out_infinite]' : ''}`}
              onClick={submitHandler}
              disabled={isLoading}
            >
              {isLoading ? (
                <span>Generating...</span>
              ) : (
                <span>Generate</span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Subscription Dialog */}
      <Transition.Root show={isDialogOpen} as={Fragment}>
        <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isDialogOpen} onClose={setIsDialogOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">Subscription Required</Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Please subscribe first to use the AI Generator.</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button onClick={() => setIsDialogOpen(false)} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Close
                  </button>
                  <button onClick={() => window.location.href='/pricing'} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Go to Pricing
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

const Index = props => {
  const router = useRouter()
  const { t } = useTranslation()
  const boardData = props.boardData ? JSON.parse(props.boardData) : null
  let boardDataTitle, boardDataDescription
  const startPagetitle = "My AI Room Designer: Design Your Dream Space Online"
  let title = !props.isStartpage ? props.moodBoardTypeName ? moodBoardTypes.find(type => type.name === props.moodBoardTypeName).title : startPagetitle : startPagetitle
  let description = props.moodBoardTypeName ? moodBoardTypes.find(type => type.name === props.moodBoardTypeName).description :  t('hero.ai-description')
  let heading = !props.isStartpage ? props.moodBoardTypeName ? moodBoardTypes.find(type => type.name === props.moodBoardTypeName).heading : startPagetitle : startPagetitle
  if(props.roomTypeName && props.roomTypeName !== "") {
    title = roomTypes.find(type => type.name === props.roomTypeName).title
    description = roomTypes.find(type => type.name === props.roomTypeName).description
    heading = roomTypes.find(type => type.name === props.roomTypeName).heading
  }
  const getDescription = (style, roomType) => {
    let description = styleDescriptions[style] || ""
    if (roomType) {
      description = description
        .replace(/{room}/g, roomType.toLowerCase())
        .replace(/{rooms}/g, roomType.toLowerCase() + "s")
    } else {
      description = description
        .replace(/{room}/g, "room")
        .replace(/{rooms}/g, "spaces")
    }
    return description
  }
  if (!props.isStartpage) {
    if (props.roomTypeName && props.style) {
      const roomType = roomTypes.find(type => type.name === props.roomTypeName)
      title = `${props.style} ${props.roomTypeName} Ideas | My AI Room Designer`
      description = getDescription(props.style, props.roomTypeName);
      heading = `${props.style} ${props.roomTypeName} Design Ideas`
    } else if (props.roomTypeName) {
      const roomType = roomTypes.find(type => type.name === props.roomTypeName)
      title = roomType.title
      description = roomType.description
      heading = roomType.heading
    } else if (props.style) {
      title = `${props.style} Ideas | My AI Room Designer`
      description = getDescription(props.style);
      heading = `${props.style} Room Design Ideas`
    } else if (props.moodBoardTypeName) {
      const moodBoardType = moodBoardTypes.find(type => type.name === props.moodBoardTypeName)
      title = moodBoardType.title
      description = moodBoardType.description
      heading = moodBoardType.heading
    }
  }
  if(boardData) {
    boardDataTitle = `${boardData.style ? boardData.style + " " : ""} ${boardData.roomType ? boardData.roomType : ""} ${boardData.colorCombination ? " with Colors of " + boardData.colorCombination : ""} | My Room Designer AI`
    
    if(boardData.seo_description) description = boardData.seo_description
    if(boardData.seo_title) {
      boardDataTitle = boardData.seo_title
      heading = boardData.seo_title
    }
  }
  const structuredData = {
    "@context":"https://schema.org",
    "@graph":[
        {
            "@type":"Organization", 
            "@id":"https://myroomdesigner.ai/#organization",
            "name":"MyRoomDesignerAI",
            "url":"https://myroomdesigner.ai",
            "sameAs":[
                "https://www.instagram.com/moodboardlyai/"
            ],
            "email":"support@myroomdesigner.ai",
            "logo":{
                "@type":"ImageObject",
                "@id":"https://myroomdesigner.ai/#logo",
                "url":"https://myroomdesigner.ai/logo.png",
                "contentUrl":"https://myroomdesigner.ai/logo.png",
                "caption":"MyRoomDesignerAI",
                "inLanguage":"en-US"
            },
            "contactPoint":[
                {
                    "@type":"ContactPoint",
                    "telephone":"+65 0000 0000",
                    "contactType":"customer support"
                }
            ]
        },
        {
            "@type":"WebSite",
            "@id":"https://myroomdesigner.ai/#website",
            "url":"https://myroomdesigner.ai",
            "name":"MyRoomDesignerAI",
            "publisher":{
                "@id":"https://myroomdesigner.ai/#organization"
            },
            "inLanguage":"en-US"
        },
        {
            "@type":"ImageObject",
            "@id":"https://myroomdesigner.ai/#primaryimage",
            "url":"https://myroomdesigner.ai/assets/ai-generator.jpg",
            "caption":"AI room design for interior designs",
            "inLanguage":"en-US"
        },
        {
            "@type":"WebPage",
            "@id":"https://myroomdesigner.ai/#webpage",
            "url":"https://myroomdesigner.ai",
            "name":"Create Stunning Room Design AI with AI",
            "datePublished":"2023-02-11T00:00:00+00:00",
            "dateModified":"2023-07-30T02:51:55+00:00",
            "isPartOf":{
                "@id":"https://myroomdesigner.ai/#website"
            },
            "primaryImageOfPage":{
                "@id":"https://myroomdesigner.ai/#primaryimage"
            },
            "inLanguage":"en-US"
        },
        {
            "@type":"Product",
            "brand":{
                "@type":"Brand",
                "name":"MyRoomDesignerAI"
            },
            "name":"Room Design AI Generator",
            "description":"Our AI generator tool helps you create stunning and professional-looking interior designs in less than 60 seconds.",
            "sku":"ai-generator",
            "category":"Design Tools",
            "mainEntityOfPage":{
                "@id":"https://myroomdesigner.ai/#webpage"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5.00",
              "bestRating": "5",
              "ratingCount": "6",
              "reviewCount": "6"
          },
            "review": [
              {
                  "@type": "Review",
                  "@id": "https://myroomdesigner.ai/#li-comment-2005901",
                  "description": "The Room Design AI from MyRoomDesignerAI has completely transformed how I approach my projects. Its efficiency and accuracy are unmatched!",
                  "datePublished": "2024-01-15T08:30:21",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Alex, Home Decorator"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://myroomdesigner.ai/#li-comment-2005902",
                  "description": "I'm amazed by the creativity and precision of Room Design AI. It has saved me countless hours!",
                  "datePublished": "2024-01-20T10:47:33",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Bella, Event Planner"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://myroomdesigner.ai/#li-comment-2005903",
                  "description": "The Room Design AI is incredibly intuitive and offers stunningly realistic designs. Highly recommend!",
                  "datePublished": "2024-02-03T14:22:45",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Carlos, Architect"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://myroomdesigner.ai/#li-comment-2005904",
                  "description": "Room Design AI is a game-changer for professionals and hobbyists alike. The detail in its designs is phenomenal.",
                  "datePublished": "2024-02-12T16:55:08",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Diana, Real Estate Developer"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://myroomdesigner.ai/#li-comment-2005905",
                  "description": "I was skeptical at first, but the Room Design AI proved to be an incredible tool for visualizing complex designs.",
                  "datePublished": "2024-02-18T09:00:00",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Ethan, Interior Designer"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://myroomdesigner.ai/#li-comment-2005906",
                  "description": "The user experience with Room Design AI is seamless. It's like having a personal assistant for design!",
                  "datePublished": "2024-03-01T12:10:27",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Fiona, Lifestyle Blogger"
                  }
              }
          ]
          
          
        }
    ]
}

 const images = props.images ? JSON.parse(props.images) : []
 const [type, setType] = useState(props.moodBoardTypeName)
 const [roomType, setRoomType] = useState(props.roomTypeName)
 const [style, setStyle] = useState(props.style)
 const [isOwner, setIsOwner] = useState(false)
 const [showHeaderSection, setShowHeaderSection] = useState(props.isStartpage)
 const [showEditor, setShowEditor] = useState(false)

 const roomName = roomType && !props.isStartpage ? roomTypes.find(room => room.name === roomType).name : "Room Design"

 useEffect(() => {
   if (router.isReady) {
     const { slug } = router.query
     const isDesignerPage = slug?.[0] === "designer"
     if (isDesignerPage) {
       setShowHeaderSection(false)
     }
   }
 }, [router.isReady, router.query])

 const Breadcrumb = () => {
   if (!props.style) return null

   return (
     <nav className="text-sm mb-4 mx-auto">
       <ul className="flex items-center space-x-2">
         <li>
           <a href="/" className="text-pink-600 hover:underline">
             Home
           </a>
         </li>
         <li className="text-gray-500">&gt;</li>
         <li>
           <a
             href="/interior-design-ai/styles"
             className="text-pink-600 hover:underline"
           >
             Styles
           </a>
         </li>
         <li className="text-gray-500">&gt;</li>
         <li className="text-gray-700">{props.style}</li>
       </ul>
     </nav>
   )
 }

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData),
          }}
        />
      </Head>
      <MetaTags
        title={boardDataTitle ? boardDataTitle : title}
        description={description}
        image={props.isStartpage ? "/assets/ai/restyle-room-demo.jpg" : boardData ? boardData.image : images.length > 0 ? images[0].image :  "/assets/ai-generator.jpg"}
        hasTranslation={false}
      />
      <LayoutFirebase>
          {boardData && !showEditor ? 
            <div className={`container px-5 pt-12 mx-auto ${showHeaderSection ? 'hidden' : ''}`}>
                  <BoardLayout boardData={boardData} setShowEditor={setShowEditor} />
                  <div className="md:mt-10 border-t border-gray-200">
                    <h2 className="mt-12 py-3 text-xl text-center text-gray-700">
                    Latest {style} {roomName}s
                  </h2>
                  <UserImages pageSize={4} images={images} type={type} roomType={roomType} style={style} linkName={'interior-design-ai'} />
                  </div>
              </div> 
            : 
            <div className={`flex flex-col text-center w-full mb-20 ${showHeaderSection ? 'hidden' : ''}`}>
              <MoodBoardAI setType={setType} setRoomType={setRoomType} setStyle={setStyle} setIsOwner={setIsOwner} heading={heading} description={description} isStartpage={props.isStartpage} type={type} roomType={roomType} style={style} images={props.images} />
            </div>
          }
        <MarketingContent isStartpage={props.isStartpage} style={style} roomName={roomName} showHeaderSection={showHeaderSection} setShowHeaderSection={setShowHeaderSection} />
        <StyleLinkList 
          styles={styles} 
          selectedRoom={roomType} 
          isStartPage={props.isStartpage}
          title={!props.isStartpage && roomType ? `${roomType} Styles` : 'Room Styles'}
        />
      </LayoutFirebase>
    </>
  )
}

export default Index



function getMoodBoardAndRoomTypeFromURL(slug, moodBoardTypes, roomTypes, styles) {
  if (!slug?.length) return {};

  // Clean the slug similar to getStaticPaths logic
  const cleanedSlug = cleanUrlSegment(slug);

  // Initialize variables to store extracted style and room type
  let extractedStyle = undefined;
  let extractedRoomType = undefined;

  // Check each style to see if it is included in the slug
  for (const style of styles) {
    if (cleanedSlug.includes(cleanUrlSegment(style))) {
      extractedStyle = style;
      break; // Stop once the first matching style is found
    }
  }

  // Check each room type to see if it is included in the slug
  for (const roomType of roomTypes) {
    if (cleanedSlug.includes(cleanUrlSegment(roomType.url))) {
      extractedRoomType = roomType.name;
      break; // Stop once the first matching room type is found
    }
  }

  return {
    style: extractedStyle,
    roomTypeName: extractedRoomType
  };
}

export const getStaticProps = async ({ locale, params }) => {
  const aiRef = await aiGenerations()

  const { slug } = params
  const isStartpage = slug === undefined || slug.length === 0
  let moodBoardTypeName = "Interior Design AI"
  let roomType = ""
  let style = ""
  let boardData = null

  if (slug?.length === 2 && slug[0] === "b") {
    // Handle case where slug is in the format '/b/BOARDID'
    const boardId = slug[1]
    const boardDoc = await aiRef.doc(boardId).get()
    if (boardDoc.exists) {
      boardData = { ...boardDoc.data(), id: boardDoc.id }
    } else {
      const boardDataFromSeoUrl = await aiRef.where("seo_url", "==", slug[1]).get()
      if (boardDataFromSeoUrl.size > 0) {
        boardData = { ...boardDataFromSeoUrl.docs[0].data(), id: boardDataFromSeoUrl.docs[0].id };
      }
    }
  } else if (slug?.length > 0) {
    const result = getMoodBoardAndRoomTypeFromURL(
      slug[0],
      moodBoardTypes,
      roomTypes,
      styles,
    )
    if (result.roomTypeName !== undefined) roomType = result.roomTypeName
    if (result.style !== undefined) style = result.style
  }
  let aiImageRef = aiRef
    .where("status", "==", "SUCCESS")
    .where("isGrid", "==", false)
    .where("type", "==", moodBoardTypeName)
    .orderBy("created", "desc")
    .limit(12)
  if (roomType) {
    aiImageRef = aiImageRef.where("roomType", "==", roomType)
  }

  if (style) {
    aiImageRef = aiImageRef.where("style", "==", style)
  }
  const aiImagesCollection = await aiImageRef.get()
  const images = aiImagesCollection.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      images: JSON.stringify(images),
      boardData: JSON.stringify(boardData),
      moodBoardTypeName,
      roomTypeName: roomType,
      style,
      isStartpage,
    },
    revalidate: 86400, // daily (24 hours)
  }
}

function cleanUrlSegment(segment) {
  return segment.toLowerCase()
                .replace(/\s+/g, '-') 
}

export async function getStaticPaths() {
  const paths = []

  const aiRef = await aiGenerations()
  const latestBoardsRef = aiRef
    .where("status", "==", "SUCCESS")
    .where("isGrid", "==", false)
    .where("type", "==", "Interior Design AI")
    .orderBy("created", "desc")
    .limit(parseInt(process.env.STATIC_LIMIT))
  const latestBoards = await latestBoardsRef.get()

  latestBoards.forEach(boardDoc => {
    const boardData = boardDoc.data()
    const boardId = boardDoc.id
    const slugValue = boardData.seo_url || boardId
    paths.push({ params: { slug: ["b", slugValue] } })
  })
  
  const pathsVariations = []
  // Flat URLs for Styles
  for (const style of styles) {
    pathsVariations.push({ params: { slug: [cleanUrlSegment(style)] } }); 
  }

  // Flat URLs for Room Types
  for (const roomType of roomTypes) {
    pathsVariations.push({ params: { slug: [cleanUrlSegment(roomType.url)] } });
  }

  // Combination URLs
  for (const roomType of roomTypes) {
    for (const colorCombination of colorCombinations) {
      for (const style of styles) {
        let url = [style, roomType.url].map(cleanUrlSegment).join('-'); // Apply to each segment
        pathsVariations.push({ params: { slug: [url] } });
      }
    }
  }

  return {
    paths: [...paths, ...pathsVariations.slice(0, parseInt(process.env.STATIC_LIMIT))],
    fallback: 'blocking', 
  };
}
